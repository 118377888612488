











































































import { Component, Watch, Mixins } from 'vue-property-decorator';

import ExerciseContainer from '@/components/Exercise/ExerciseContainer/ExerciseContainer.vue';
import MapQuestions from '@/components/Exercise/MapQuestions/MapQuestions.vue';
import ResizeText from '@/components/Exercise/ResizeText/ResizeText.vue';
import Question from '@/components/Exercise/Question/Question.vue';
import RadioAlternative from '@/components/Exercise/RadioAlternative/RadioAlternative.vue';
import NavigationQuestion from '@/components/Exercise/NavigationQuestion/NavigationQuestion.vue';
import ConfigMapQuestions from '../../mixins/ConfigMapQuestions';

const INDEX_FIRST_QUESTION_TAB = 0;

@Component({
  components: {
    ExerciseContainer,
    MapQuestions,
    ResizeText,
    Question,
    RadioAlternative,
    NavigationQuestion
  }
})
export default class ContainerQuestion extends Mixins(ConfigMapQuestions) {
  private fontSizeQuestion = 16;
  private fontSizeOptions = 14;

  get isMaximumFontSizeQuestion() {
    return this.fontSizeQuestion === 20;
  }

  get isMinimumFontSizeQuestion() {
    return this.fontSizeQuestion === 12;
  }

  get isMaximumFontSizeOptions() {
    return this.fontSizeOptions === 18;
  }

  get isMinimumFontSizeOptions() {
    return this.fontSizeOptions === 10;
  }

  @Watch('isAllQuestions')
  setQuestionTab() {
    if (!this.isAllQuestions) {
      this.questionTab = this.questions[this.tabActive][INDEX_FIRST_QUESTION_TAB];
      this.currentNumbersQuestionTab[this.tabActive] = this.currentNumberQuestion() + 1;
      this.indexQuestion[this.tabActive] = INDEX_FIRST_QUESTION_TAB;
    } else this.indexQuestion[this.tabActive] = INDEX_FIRST_QUESTION_TAB;
  }

  increaseText() {
    this.fontSizeQuestion = this.isMinimumFontSizeQuestion
      ? this.fontSizeQuestion
      : this.fontSizeQuestion - 1;
    this.fontSizeOptions = this.isMinimumFontSizeOptions
      ? this.fontSizeOptions
      : this.fontSizeOptions - 1;
  }

  decreaseText() {
    this.fontSizeQuestion = this.isMaximumFontSizeQuestion
      ? this.fontSizeQuestion
      : this.fontSizeQuestion + 1;
    this.fontSizeOptions = this.isMaximumFontSizeOptions
      ? this.fontSizeOptions
      : this.fontSizeOptions + 1;
  }

  answerQuestion(
    indexAlternative: number,
    idQuestion: number,
    indexQuestion: number,
    idOption: number
  ) {
    if (this.isAllQuestions) this.indexQuestion[this.tabActive] = indexQuestion;
    this.$emit('answerQuestion', { question_id: idQuestion, option_id: idOption }, indexQuestion);
  }
}
