





































import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_COLOR = 'var(--neutralcolor-low-pure)';

@Component
export default class QuestionSizeSymbol extends Vue {
  @Prop({ default: DEFAULT_COLOR }) fill!: string;
}
