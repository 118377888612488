









































import { Component, Vue } from 'vue-property-decorator';

import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Compressor from 'compressorjs';

import Loading from '@/components/Loading/Loading.vue';

import iPlus from '@/assets/icons/Plus.vue';

import RedactionService from '@/services/Redaction/RedactionService';

import CropImage from '@/components/CropImage/CropImage.vue';

interface DataImage {
  data: {
    url: string;
  };
}

const DEFAULT_FILL = 'var(--neutralcolor-low-dark)';

@Component({
  components: {
    Loading,
    vueDropzone,
    iPlus,
    CropImage
  }
})
export default class ImageRedaction extends Vue {
  private dropzoneOptions = {
    url: `${process.env.VUE_APP_API_STATIC_CDN}/photos`,
    thumbnailWidth: 200,
    addRemoveLinks: true,
    uploadMultiple: false,
    maxFiles: 1,
    acceptedFiles: 'image/jpeg,image/jpg,image/png',
    dictRemoveFile: 'Remover',
    dictCancelUpload: 'Cancelar',
    dictInvalidFileType: 'Extensão de arquivo não suportado.',
    dictMaxFilesExceeded: 'Permitido apenas um arquivo.',
    dictResponseError: 'Erro interno. Tente mais tarde.',
    dictCancelUploadConfirmation: 'Tem certeza que deseja cancelar o upload?',
    transformFile: this.handleTransformFile
  };

  private isLoading = false;

  private urlImageRedaction = '';
  private imageOriginal = '';
  private DEFAULT_FILL = DEFAULT_FILL;

  private afterCropImage = false;
  private isShowCrop = false;

  private dropzoneRef: any = null;

  private RedactionService = new RedactionService();

  mounted() {
    this.setRefDropzone();
  }

  handleTransformFile(file: any, done: any) {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.6,
      convertTypes: ['image/jpg,image/png'],
      convertSize: 1000000,
      maxWidth: 2000,
      maxHeight: 2000,
      checkOrientation: true,

      success(result) {
        done(result);
      },
      error(err) {
        console.error(err.message);
      }
    });
  }

  setRefDropzone() {
    const { dropzone } = this.$refs;
    this.dropzoneRef = dropzone;
  }

  uploadImage(file: object, response: DataImage) {
    const { data } = response;

    if (data.url && !this.afterCropImage) {
      this.imageOriginal = data.url;
      this.urlImageRedaction = data.url;
      this.isShowCrop = true;
    } else {
      this.urlImageRedaction = data.url;
      this.isShowCrop = false;
    }

    this.$emit('imageRedaction', this.urlImageRedaction);
  }

  onRemovedFile() {
    this.urlImageRedaction = '';
    this.afterCropImage = false;

    this.$emit('imageRedaction', this.urlImageRedaction);
  }

  async sendImage(image: Blob) {
    if (this.dropzoneRef) {
      this.dropzoneRef.removeAllFiles();

      if (!this.afterCropImage) {
        this.afterCropImage = true;
      }

      const myFile = new File([image], 'redaçao.png', {
        type: 'image/jpeg'
      });

      const dropzone = this.dropzoneRef;

      // eslint-disable-next-line no-new
      new Compressor(myFile, {
        quality: 0.6,
        convertTypes: ['image/jpg,image/png'],
        convertSize: 1000000,
        maxWidth: 2000,
        maxHeight: 2000,
        checkOrientation: true,

        success(result) {
          dropzone.addFile(result);
        },
        error(err) {
          console.error(err.message);
        }
      });
    }
  }

  canceledImage() {
    this.isShowCrop = false;
    this.afterCropImage = true;
  }

  showCropModal() {
    this.isShowCrop = true;
  }
}
