


















































































import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

@Component({
  components: {
    ExLoading
  }
})
export default class SimulatedQuestionLoading extends Vue {
  private quantityLoadingQuestion = 3;
  private quantityLoading = 4;
}
