









































































import { Component, Vue } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import PrintRedactionSheet from '@/components/Redaction/PrintRedactionSheet/PrintRedactionSheet.vue';

import iSizeText from '@/assets/icons/SizeText.vue';
import iArrow from '@/assets/icons/Arrow.vue';
import iSearch from '@/assets/icons/Search.vue';
import iPrint from '@/assets/icons/Print.vue';

const CONTAINER_SIZE = 1044;
const MINIMUM_QUANTITY_WORD = 30;

const DEFAULT_FILL = 'var(--neutralcolor-low-dark)';

@Component({
  components: {
    VueEditor,
    PrintRedactionSheet,
    iSizeText,
    iArrow,
    iSearch,
    iPrint
  }
})
export default class RedactionDigited extends Vue {
  private content = '';
  private zoom = 1;
  private DEFAULT_FILL = DEFAULT_FILL;
  private isLimitContainerRedaction = false;
  private customToolbar = [[{ header: [3, 4, false] }]];
  private editorOptions = {
    placeholder: 'Respire fundo e comece escrever...',
    modules: {
      toolbar: '#toolbar'
    },
    formats: ['font', 'size', 'header']
  };

  $refs!: {
    vue: Vue;
    PdQuillEditor: any;
  };

  get editor() {
    return this.$refs.PdQuillEditor.quill;
  }

  treatDoubleQuotes(content: string) {
    const replaceFirstQuote = content.replace(/”/g, '"');
    const replaceSecondQuote = replaceFirstQuote.replace(/“/g, '"');
    return replaceSecondQuote;
  }

  onTextChange() {
    this.$emit('dataRedaction', {
      isLimitText: this.verifyHeightContainerRedaction(),
      contentRedaction: this.treatDoubleQuotes(this.content),
      haveMinimumWord: this.verifyQuantityWords()
    });
  }

  verifyHeightContainerRedaction() {
    const heightContainer = this.editor.container.clientHeight;

    const isLimitContainer = heightContainer > CONTAINER_SIZE;

    this.isLimitContainerRedaction = isLimitContainer;

    return isLimitContainer;
  }

  verifyQuantityWords() {
    const quantityWords = this.editor
      .getText()
      .split(' ')
      .filter((content: string) => content).length;

    return quantityWords > MINIMUM_QUANTITY_WORD;
  }

  setZoomRedaction(value: string) {
    switch (value) {
      case 'increase':
        this.zoom = this.zoom <= 0.9 && this.zoom >= 0.6 ? this.zoom + 0.1 : this.zoom;
        break;
      case 'decrease':
        this.zoom = this.zoom >= 0.7 ? this.zoom - 0.1 : this.zoom;
        break;
      default:
        console.error(
          'Por algum motivo não conseguimos realizar o zoom. Recarregue a página e tente novamente.'
        );
    }
  }
}
