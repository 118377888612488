import { render, staticRenderFns } from "./QuestionSizePositive.vue?vue&type=template&id=6a557b7c&scoped=true&"
import script from "./QuestionSizePositive.vue?vue&type=script&lang=ts&"
export * from "./QuestionSizePositive.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a557b7c",
  null
  
)

export default component.exports