






























import { Component, Vue, Prop } from 'vue-property-decorator';
import Modal from '@/globalComponents/Modal/Modal.vue';

const FIRST_DAY_SIMULATED = 1;
const SECOND_DAY_SIMULATED = 2;
const NAME_FIRST_DAY = 'primeiro';

@Component({
  components: {
    Modal
  }
})
export default class ModalWarningRedaction extends Vue {
  @Prop() simulatedDay!: string;

  handleClickButtonFinish() {
    this.$emit('click-button-finish');
  }

  handleClickButtonStartRedaction() {
    this.$emit('click-button-start-redaction');
  }

  get numberDay() {
    return this.simulatedDay === NAME_FIRST_DAY ? FIRST_DAY_SIMULATED : SECOND_DAY_SIMULATED;
  }
}
