


















import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal
  }
})
export default class ModalTimeFinishSimulated extends Vue {
  sendSimulated() {
    this.$emit('finish-simulated');
  }
}
